@import "../../../styles/colors.scss";

.formContainer {
  .mainBtn {
    border-radius: 4px;
    &:hover {
      color: $color-main-pink;
      border-color: $color-main-pink;
    }
  }
}
