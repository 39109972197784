@import "../../styles/colors.scss";

.container {
  background-color: $color-main-dark-blue !important;
  height: max-content;
  min-height: 100%;
  position: absolute !important;
  width: 100%;
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
    overflow: hidden;
    margin-top: 100px;
    .mainBtn {
      border-radius: 4px;
      width: 100px;
      margin-top: 20px;
      &:hover {
        color: $color-main-pink;
        border-color: $color-main-pink;
      }
    }
    .inputs {
      padding: 10px;
      background-color: $color-main-pink;
    }
  }
  .tabs {
    :global(.ant-tabs-nav) {
      background-color: $color-secondary-dark-blue;
      &::before {
        border-bottom: 1px solid $color-secondary-dark-blue !important;
      }
      :global(.ant-tabs-nav-list) {
        :global(.ant-tabs-tab.ant-tabs-tab-active) {
          :global(.ant-tabs-tab-btn) {
            color: $color-main-pink !important;
          }
        }
        :global(.ant-tabs-ink-bar) {
          background-color: $color-main-pink;
        }
      }
    }

    :global(.ant-tabs-content-holder) {
      padding: 10px 20px 0px 20px;
    }
  }
}
.active {
  color: #ee3e95;
}
